<template>
	<div>
        <v-row v-if="!loading && !permissions.can_view">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <p class="text-h5 mb-4">Payslip Management</p>
                <p class="text-title mb-0">You do not have permission to view all payslips</p>
            </v-col>
        </v-row>
        <template v-if="permissions.can_view">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-sheet color="white" elevation="0" class="pa-0">
                        <p class="text-h6 mb-4">Search</p>
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <v-text-field dense clearable hide-details label="Name" v-model="filters.name"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <v-text-field dense clearable hide-details label="NRIC" v-model="filters.nric"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" order="last">
                                <v-btn small color="primary" class="mr-2 elevation-0" :ripple="false" @click='search'>
                                    <v-icon small left>mdi-magnify</v-icon> Search
                                </v-btn>
                                <v-btn small class="elevation-0" :ripple="false" @click='reset'>
                                    <v-icon small left>mdi-undo</v-icon> Reset
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-sheet color="white" elevation="0" class="pa-0">
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <display-label label="Start Date" :text="object.start_date"></display-label>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <display-label label="End Date" :text="object.end_date"></display-label>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <display-label label="No. of Payslips" :text="object.quantity.toString()"></display-label>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-btn small color="primary" class="elevation-0" :ripple="false" @click="openCreateDialog" v-if="permissions.can_edit">Update Payslip PDFs</v-btn>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="text-right" v-if="hasDownloadMenu()">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn small color="primary" class="elevation-0" :ripple="false" v-bind="attrs" v-on="on" >Download Files</v-btn>
                        </template>
                         <v-list>
                            <v-list-item @click="openDownloadDialog('payslip')" v-if="permissions.can_download_report">
                                <v-list-item-title>Download Payslip Report</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="openDownloadDialog('cpf')" v-if="permissions.can_download_cpf">
                                <v-list-item-title>Download CPF Report</v-list-item-title>
                            </v-list-item>
                            <v-list-item :href="object.zip_url" target="_blank" v-if="object.zip_url">
                                <v-list-item-title>Download Payslip Zip</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row v-if="permissions.can_list">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-data-table hide-default-footer class="elevation-0" loading-text="Loading... Please wait..." no-data-text="No data available yet" :loading="loading" :items-per-page="50" :headers="headers" :items="items">
                                <template v-slot:item='{ item, index }'>
                                    <tr>
                                        <td class="text-center" v-text="index + 1"></td>
                                        <td class="text-center" v-text="item.name"></td>
                                        <td class="text-center green--text" v-text="item.ordinary"></td>
                                        <td class="text-center green--text" v-text="item.additional"></td>
                                        <td class="text-center green--text" v-text="item.reimbursement"></td>
                                        <td class="text-center red--text" v-text="item.agency_fund"></td>
                                        <td class="text-center red--text" v-text="item.employee_cpf"></td>
                                        <td class="text-center" v-text="item.employer_cpf"></td>
                                        <td class="text-center primary--text" v-text="item.net_amount"></td>
                                        <td class="text-center">
                                            <v-menu bottom offset-y v-if="hasMenu(item)">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn small icon v-bind="attrs" v-on="on">
                                                        <v-icon>mdi-dots-horizontal</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list dense>
                                                    <v-list-item :href="item.pdf_url" target="_blank" v-if="item.pdf_url">
                                                        <v-list-item-title>View Payslip PDF</v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item @click="openCreateDialog(item, index)" v-if="item.can_edit">
                                                        <v-list-item-title>Update Payslip PDF</v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-row v-if="!loading && pagination.total_pages > 1">
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-pagination circle :length="pagination.total_pages" :total-visible="7" v-model="filters.page" @input="get"></v-pagination>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </template>
        <create-dialog ref="createDialog" @created="updateItems"></create-dialog>
        <create-dialog is-single ref="updateDialog" @created="updateItem"></create-dialog>
        <download-dialog ref="downloadDialog" @downloaded="handleDownloaded"></download-dialog>
        <simple-action-dialog :has-cancel="false" ref="loadingDialog" title="Preparing..." text="System is preparing the report now. Please be patient." confirm-text="OK"></simple-action-dialog>
        <message-notifier ref="notifier"></message-notifier>
	</div>
</template>


<script>

import PayrollPayslipGenerateDialog from './PayrollPayslipGenerateDialog'
import PayrollPayslipDownloadDialog from './PayrollPayslipDownloadDialog'
import DisplayLabel from '../../../shared/DisplayLabel.vue'
import SimpleActionDialog from '../../../shared/SimpleActionDialog.vue'
import MessageNotifier from '../../../shared/MessageNotifier.vue'


export default {
	name: 'PayrollPayslipInfo',
	components: {
        createDialog: PayrollPayslipGenerateDialog,
        downloadDialog: PayrollPayslipDownloadDialog,
        displayLabel: DisplayLabel,
        simpleActionDialog: SimpleActionDialog,
        messageNotifier: MessageNotifier,
	},
	data () {
		return {
			loading: false,
			selectedIndex: -1,
            filters: {
                name: '',
                nric: '',
                page: 1
            },
			object: {
                start_date: '',
                end_date: '',
                quantity: '0',
                zip_url: '',
                total_gross: '',
                total_reimbursement: '',
                total_additional: '',
                total_deduction_before_cpf: '',
                total_deduction_after_cpf: '',
                total_amount: ''
			},
            permissions: {
                can_view: false,
                can_edit: false,
                can_list: false,
                can_view_items: false,
                can_download_report: false,
                can_download_cpf: false,
                can_download_zip: false
            },
			headers: [],
			items: [],
			pagination: {}
		}
	},
	computed: {
        table() {
            return this.$refs.table
        },
        createDialog() {
            return this.$refs.createDialog
        },
        updateDialog() {
            return this.$refs.updateDialog
        },
        downloadDialog() {
            return this.$refs.downloadDialog
        },
        loadingDialog() {
            return this.$refs.loadingDialog
        },
        notifier() {
            return this.$refs.notifier
        }
	},
	mounted: function() {
		this.get()
	},
	methods: {
        get: function() {
			this.loading = true
			this.items = []
            this.$store.dispatch('epanel/payroll/getPayrollPayslipAccounts', { id: this.$route.params['id'] }).then((response) => {
                this.object = response.data.object
                this.items = response.data.items
                this.headers = response.data.headers
                this.pagination = response.data.pagination
                this.permissions = response.data.permissions
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },
        search: function() {
            this.filters.page = 1
            this.get()
        },
        reset: function() {
            this.filters = { name: '', nric: '', page: 1 }
        },
        updateItems: function(item){
            this.get()
            this.openNotifier(item.message)
        },
        updateItem: function(item){
            this.$set(this.items, this.selectedIndex, item.object)
            this.openNotifier(item.message)
        },
        handleDownloaded: function(){
            this.loadingDialog.open()
        },
        openCreateDialog: function() {
            if(this.permissions.can_edit){
                this.createDialog.open()
            }
        },
        openUpdateDialog: function(item, index) {
            if(item.can_edit){
                this.selectedIndex = index
                this.updateDialog.updateObject({'account': item.id })
                this.updateDialog.open()
            }
        },
        openDownloadDialog: function(type) {
            if(this.permissions.can_download_cpf || this.permissions.can_download_report){
                const object = { type: type, payslip: this.$route.params['id'] }
                this.downloadDialog.updateObject(object)
                this.downloadDialog.open()
            }
        },
        openNotifier: function(message) {
            this.notifier.updateText(message)
            this.notifier.open()
        },
        hasDownloadMenu: function(){
            return this.permissions.can_download_report || this.permissions.can_download_cpf || this.permissions.can_download_zip
        },
        hasMenu: function(item) {
            return item.pdf_url || item.can_edit
        }
	}
}

</script>


<style scoped>


</style>