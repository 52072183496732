<template>
    <v-dialog persistent max-width="764" v-model="dialog">
        <v-form ref="form" action="/report/payroll/payslip/download/">
            <v-card>
                <v-card-title v-text="getTitleText()"></v-card-title>
                <v-card-text class="pb-0 my-1">
                    <input type="hidden" name="payslip" :value="object.payslip" />
                    <input type="hidden" name="type" :value="object.type" />
                    <span class="text-subtitle-1" v-text="getContentText()"></span>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="close">Later</v-btn>
                    <v-btn text color="primary" @click="download">Download</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>


<script>

export default {
    name: 'PayrollPayslipDownloadDialog',
    data() {
        return {
            dialog: false,
            object: {
                type: '',
                payslip: ''
            }
        }
    },
    computed: {
        form() {
            return this.$refs.form
        }
    },
    methods: {
        open: function() {
            this.dialog = true
        },
        close: function() {
            this.dialog = false
        },
        updateObject: function(item) {
            this.object = item
        },
        download: function() {
            this.form.$el.submit()
            this.close()
            this.$emit('downloaded')
        },
        getTitleText: function() {
            if(this.object.type === 'payslip'){
                return 'Download Payslip Report'
            }else if(this.object.type === 'cpf'){
                return 'Download CPF Report'
            }
            return ''
        },
        getContentText: function() {
            if(this.object.type === 'payslip'){
                return 'Are you sure that you want to download the payslip report?'
            }else if(this.object.type === 'cpf') {
                return 'Are you sure that you want to download the CPF report?'
            }
            return ''
        }
    }
}

</script>