<template>
    <v-dialog persistent max-width="764" v-model="dialog">
        <v-card>
            <v-card-title v-text="getTitleText()"></v-card-title>
            <v-card-text class="px-4 pb-0 my-1">
                <v-row>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pb-0" v-if="errors && errors.message">
                        <v-alert dense outlined type="error" class="mb-0"><span v-text="errors.message"></span></v-alert>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <v-alert dense class="text-subtitle-1" type="warning">
                            <span class="text-subtitle-1" v-text="getContentText()"></span>
                        </v-alert>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="pl-4 pr-4">
                <v-spacer></v-spacer>
                <v-btn text :disabled="loading" @click="close">Later</v-btn>
                <v-btn text color="primary" :loading="loading" @click="save">Update</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>

export default {
    name: 'PayrollPayslipGenerateDialog',
    props: {
        isSingle: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            dialog: false,
            loading: false,
            object: {
                account: ''
            },
            errors: {}
        }
    },
    methods: {
        open: function() {
            this.dialog = true
        },
        close: function() {
            this.dialog = false
            this.object = { account: '' }
        },
        save: function() {
            this.loading = true
            this.$store.dispatch('epanel/payroll/createPayrollPayslipAccounts', { id: this.$route.params['id'], object: this.object}).then(() => {
                this.loading = false
                this.close()
                this.$emit('created')
			}).catch((errors) => {
                this.errors = errors.data?errors.data.errors:errors
                this.loading = false
			})
        },
        updateObject: function(item) {
            this.object.account = item.account?item.account:''
        },
        getTitleText: function() {
            return this.loading?'Updating':'Update Payslip PDF'
        },
        getContentText: function() {
            if(!this.isSingle){
                return this.loading?'System is updating the payslips now. Please be patient.':'Are you sure that you want to update all applicable promoters?'
            }else{
                return this.loading?'System is updating the payslips now. Please be patient.':'Are you sure that you want to update pdf for this promoters?'
            }
        }
    }
}

</script>